@import 'content/header';
@import 'content/card';
@import 'content/text';
@import 'content/ripple';
@import 'content/button';
@import 'content/contact-form';
@import 'content/image';

p.content {
  display: none;
  width: 100%;
  height: 100%;


  &.active, &.default {
    display: block;
  }
}

.container {
  width: 100%;

  &.with-top-padding {
      padding-top: 50px;
  }

  &.first {
    display: flex;
    align-items: center;
    justify-items: center;
    min-height: 100%;
    flex-direction: column;
    
    &:not(.device-is-mobile) {
      margin-left: 20vw!important;
      margin-right: 20vw!important;
    }

    & .header {
      text-align: center;
    }

    & .subtitle {
      font-weight: bold;
      width: 100%;
      text-align: center;
      margin-bottom: 1.424rem;
    }
  }

  &.main {
    text-align: justify;
    text-justify: inter-word;

    width: auto;
    margin-left: 10vw;
    margin-right: 10vw;

    &.device-is-mobile {
      margin-left: 2vw;
      margin-right: 2vw;
    }
  }
}