@import "menu";
@import "projects";

main {
    display: flex;
    justify-content: center;
    
    .main-container {
        padding: 0 1rem;
        width: 100%;
        margin-top: 1rem;
        margin-right: auto;
        margin-left: auto;

        @media (min-width: 768px) {
            max-width: 720px;
        }

        @media (min-width: 996px) {
            max-width: 960px;
        }

        @media (min-width: 1200px) {
            max-width: 1140px;
        }
    }
}