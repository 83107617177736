.header{
    transition: all .4s ease;
    text-transform: uppercase;
    color: $content-header-color;
    width: 100%;
    text-align: left;

    overflow-wrap: break-word;

    &.main {
        font-size: 9em;

        &.device-is-mobile {
            font-size: 4em;
        }
    }

    &.sub-main {
        &.device-is-mobile {
            font-size: 2.1rem;
        }
    }
}