.projects {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;

  .project {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding: 0 1rem;
    position: relative;
    background-color: transparent;
    border: none;
    border-radius: 0;
    width: 100%;

    .wrapper {
      border-radius: 10px;
      box-shadow: inset 0 0 0 1px #ebebf0, 0 15px 30px 0 rgba(0, 0, 0, 0.15);
      height: 100%;

      .image {
        padding: 2rem;

        border-radius: 10px;

        &:after {
          display: block;
          content: "";
          /* 16:9 aspect ratio */
          padding-bottom: 56.25%;
        }

        .wrapper {
          position: relative;
          overflow: hidden !important;
          border-radius: 10px;

          img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
          }
        }
      }

      .content {
        padding: 3rem;

        .header {
          font-size: 1.2rem;
          padding-bottom: 10px;
        }

        p {
          font-size: 1rem;
          padding-bottom: 10px;
          text-align: left;
          font-style: normal;
          line-height: 1.6;
          width: 100%;
          color: #48525c;
          font-weight: 400;
        }
      }
    }
  }
}

.full.project {
  display: flex;
  flex-wrap: wrap;

  & > * {
    padding: 3rem;
    margin: .5rem;
    border-radius: 10px;
    border: $accent1Color 1px solid;
  }

  .header {
    flex-basis: 100%;

    * {
        font-size: 1.5rem;
    }
  }

  .images {
    flex-basis: 100%;
    display: flex;
    align-content: center;

    .image {
      flex-basis: 50%;
      overflow: hidden !important;
      position: relative;

      &:after {
        display: block;
        content: "";
        /* 16:9 aspect ratio */
        padding-bottom: 56.25%;
      }
  
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
