$hamburger-menu-size: 60px;

// Handle below small screen
@media #{$medium-and-down} {
  $hamburger-menu-size: $hamburger-menu-size * 1.5;
}

// Handle below small screen
@media #{$small-and-down} {
  $hamburger-menu-size: $hamburger-menu-size * 2;
}

$hamburger-menu-border-radius: 0.12em;
$hamburger-menu-line-height: $hamburger-menu-size / 20;
$hamburger-menu-padding-left-right: $hamburger-menu-size / 6;
$hamburger-menu-padding-top-bottom: $hamburger-menu-size / 6;

$hover-line-height: $hamburger-menu-line-height;
$hover-color: darken(#fff, 10%);

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullscreen-navigation {
  @extend .flex-center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  backface-visibility: hidden;
  outline: 1px solid transparent;
  color: $menuItemColor;
  background: $menuBackground;
  transition: background-color $animation-duration ease;
  flex-direction: column;

  .image {
    @extend .flex-center;

    img {
      height: 5vh;
      max-height: $menuHeight * 2;
    }
  }

  > .footer {
    height: 10vh;
    width: 100vw;
    @extend .flex-center;
    justify-content: space-between;
    /*-webkit-box-shadow: inset 0px 0px 25px 1px rgba(50,50,50,0.5); 
    box-shadow: inset 0px 0px 25px 1px rgba(50,50,50,0.5);*/

    @media #{medium-and-up} {
      .copyright {
        padding-left: 2em!important;
      }
    }

    @media #{$small-and-down} {
      height: 15vh;
      flex-direction: column;
      justify-content: center;
      & > * {
        padding: 0.4rem!important;
      }

      & li {
        margin: .3em;
        font-size: 100%;
      }
    }

    & > * {
      padding: 1rem;
    }

    .copyright {
      text-align: left;
    }

    .items {
      ul {
        @extend .flex-center;
        list-style-type: none;
        li {
          padding: 0;
          font-weight: 300;
          font-size: 1.5rem;

          &.device-is-mobile {
            font-size: 1rem !important;
          }

          &.copyright.device-is-mobile {
            display: none;
          }

          a {
            &:not(.device-is-mobile):after {
              height: $hover-line-height;
              bottom: -0.14em;
            }
          }
        }
      }
    }
  }

  > .items {
    @extend .flex-center;
    text-align: center;
    width: 100vw;
    height: 90vh;
    overflow-y: auto;
    flex: none;

    @media #{$small-and-down} {
      height: 85vh!important;
    }
  }
}

ul.menu-items-animated {
  list-style: none;
  padding: 0 1em;
  margin: 0;
  display: block;
  max-height: 100vh;

  > li {
    //every menu element
    padding: 0;
    margin: 1em;
    font-weight: 300;
    font-size: 3.3rem;

    display: block;

    > a {
      position: relative;
      display: inline;
      cursor: pointer;
      color: $menu-item-text-color;
      transition: color $animation-duration ease;

      &:hover {
        color: $menu-item-text-color-hover;

        &:after {
          width: 100%;
        }
      }

      &:after {
        content: "";
        position: absolute;
        z-index: $menu-overlay-z-index;
        bottom: -0.09em;
        left: 0;
        width: 0;
        height: $hover-line-height;
        background: $menuItemColor;
        transition: width $animation-duration ease;
      }
    }
  }
}