@import "./menu/fullscreen-menu";

$hamburger-menu-size: 60px;

// Handle below small screen
@media #{$medium-and-down} {
  $hamburger-menu-size: $hamburger-menu-size * 1.5;
}

// Handle below small screen
@media #{$small-and-down} {
  $hamburger-menu-size: $hamburger-menu-size * 2;
}

$hamburger-menu-border-radius: 0.12em;
$hamburger-menu-line-height: $hamburger-menu-size / 20;
$hamburger-menu-padding-left-right: $hamburger-menu-size / 6;
$hamburger-menu-padding-top-bottom: $hamburger-menu-size / 6;

$hover-line-height: $hamburger-menu-line-height;
$hover-color: darken(#fff, 10%);

header {
  min-height: $menuHeight;
  overflow: visible;
  padding: 0;

  nav {
    z-index: 100;
    width: 100%;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
    position: fixed;
    top: 0;

    .menu {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: $menuHeight;

      @media (max-width: 991px) {
        max-width: 100%;
        padding: 0.5rem 1rem;
      }

      @media (max-width: 767px) {
        padding: 0.5rem 1rem;
      }

      .hamburger {
        position: fixed;
        top: 0;
        left: 0;
        z-index: $menu-overlay-z-index;
        transition: all $animation-duration ease;

        &.checked {
          .hamburger {
            &:hover > div{
              background: $menuHamburgerCheckedColorHover;
            }

            > div {
              background: $menuHamburgerCheckedColor;
              transform: rotate(135deg);
  
              &:before,
              &:after {
                top: 0;
                transform: rotate(90deg);
              }
  
              &:after {
                opacity: 0;
              }
            }
          }

          &:hover + .hamburger > div {
            transform: rotate(225deg);
          }
        }

        &:hover + .hamburger {
          box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
        }

        .hamburger {
          @extend .flex-center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          z-index: $menu-overlay-z-index;
          width: $hamburger-menu-size;
          height: $hamburger-menu-size;
          padding: $hamburger-menu-padding-top-bottom
            $hamburger-menu-padding-left-right;
          border-radius: 0 $hamburger-menu-border-radius
            $hamburger-menu-border-radius 0;
          cursor: pointer;
          transition: all $animation-duration ease;
          backface-visibility: hidden;
          overflow: hidden;
          color: $menuHamburgerColor;
          text-align: center;

          &:hover > div {
            background: $menuHamburgerColorHover;
          }

          > div {
            @extend .flex-center;
            position: relative;
            flex: none;
            width: $hamburger-menu-size - $hamburger-menu-padding-left-right -
              $hamburger-menu-padding-left-right;
            height: $hamburger-menu-line-height;
            background: $menuHamburgerColor;
            transition: all $animation-duration ease;

            &:before,
            &:after {
              content: "";
              position: absolute;
              z-index: $menu-overlay-z-index + 1;
              top: -$hamburger-menu-size / 6;
              left: 0;
              width: 100%;
              height: $hamburger-menu-line-height;
              background: inherit;
              transition: all $animation-duration ease;
            }

            &:after {
              top: $hamburger-menu-size / 6;
            }
          }
        }
      }

      .logo {
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-height: $menuHeight - 20px;

          @media (max-width: 767px) {
            max-width: 50%;
          }
        }
      }
    }
  }
}
