.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

.form.contact {
    width: 100vw;
    height: 100vh !important;

    z-index: $contact-form-z-index;

    background: $content-contact_form-background-color;

    position: absolute;

    left: 100%;
    top: 0;

    transition: left .4s ease-out;

    &.active {
        left: 0;
    }

    &.hide-fast {
        transition: none;
    }

    .back {
        position: absolute;
        height: $content-top_bar-height;
        right: 0;
        top: 0;

        display: flex;
        align-items: center;
    }

    .wrapper {
        @extend .flex-center;
        position: absolute;
        top: $content-top_bar-height;
        left: 0;
        right: 0;
        bottom: 0;

        form {
            z-index: $contact-form-form-fields-label - 1;
            background: white;
            .fields {
                .field {
                    margin: 2em;
                    position: relative;

                    input,
                    textarea {
                        color: $content-contact_form-form-field-color;
                        width: 100%;
                        box-sizing: border-box;
                        letter-spacing: 1px;
                        border: $content-contact_form-form-field-border-thickness solid $content-contact_form-form-field-border-color;
                        padding: ($content-contact_form-form-field-height / 2) $content-contact_form-form-field-height;
                        transition: 0.4s;
                        background: transparent;
                        z-index: $contact-form-form-fields-input;

                        &~label {
                            position: absolute;
                            left: $content-contact_form-form-field-height;
                            width: 100%;
                            top: $content-contact_form-form-field-height / 2;
                            color: $content-contact_form-form-field-color;
                            transition: 0.3s;
                            z-index: $contact-form-form-fields-label;
                            letter-spacing: 0.5px;
                        }

                        &:focus,
                        &.has-content {
                            &~label {
                                top: -($content-contact_form-form-field-height + 1);
                                left: 0;
                                font-size: $content-contact_form-form-field-height / 14 * 12;
                                color: $content-contact_form-form-field-effect-color;
                                transition: 0.3s;
                            }

                            &~.focus-border {

                                &:before,
                                &:after {
                                    width: 100%;
                                    transition: 0.2s;
                                    transition-delay: 0.6s;
                                }

                                &:after {
                                    transition-delay: 0.2s;
                                }

                                & i {

                                    &:before,
                                    &:after {
                                        height: 100%;
                                        transition: 0.2s;
                                    }

                                    &:after {
                                        transition-delay: 0.4s;
                                    }
                                }
                            }
                        }

                        &:focus {
                            outline: none;
                        }

                        &~.focus-border {

                            &:before,
                            &:after {
                                content: "";
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: 0;
                                height: $content-contact_form-form-field-effect-thickness;
                                background-color: $content-contact_form-form-field-effect-color;
                                transition: 0.2s;
                                transition-delay: 0.2s;
                            }

                            &:after {
                                top: auto;
                                bottom: 0;
                                right: auto;
                                left: 0;
                                transition-delay: 0.6s;
                            }

                            & i {

                                &:before,
                                &:after {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: $content-contact_form-form-field-effect-thickness;
                                    height: 0;
                                    background-color: $content-contact_form-form-field-effect-color;
                                    transition: 0.2s;
                                }

                                &:after {
                                    left: auto;
                                    right: 0;
                                    top: auto;
                                    bottom: 0;
                                    transition-delay: 0.4s;
                                }
                            }

                        }
                    }
                }
            }

            .actions {
                width: 100%;

                display: flex;
                justify-content: space-evenly;

                a {
                    padding: 1em;
                    margin: 1em;
                    color: $p-color;
                    flex: 1 50%;
                    text-align: center;

                    transition: all .2s ease-out;

                    &:hover {
                        color: $p-color-dark;
                    }
                }
            }
        }
    }
}