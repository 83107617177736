* {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    margin: 0;
    padding: 0;
  }
  @import 'variables/variables';
  @import 'variables/ext-variables';
  
  
  @import url('https://fonts.googleapis.com/css?family=Pacifico|Open+Sans:300');
  
  @import 'content';
  
  @import "./components/components";
  
  @import 'normalize';
  
  html, body{
    min-height: 90vh;
    overflow-x: hidden;
  }
  
  body {
    background-color: $main-background-color;
  
    &.cut {
      overflow-y: hidden;
      height: 100vh!important;
    }
  }

  .react-markdown-wrapper p {
    font-size: 100%;
  }