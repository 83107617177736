$card-border-color: lightgrey;

.card {
    border-radius: 3px;
    margin: 5px;
    width: 25%;
    border: 1px solid $card-border-color;

    > .content {
        width: 100%;
        padding: 10px;
        margin: 0;

        &.top{
            border-bottom: 1px solid $card-border-color;
            margin-bottom: 5px;
        }

        > .header {
            font-weight: 700;
        }
    }

    &.raised{
        -webkit-box-shadow: 0px 2px 5px 5px rgba(50, 50, 50, 0.35);
        -moz-box-shadow:    0px 2px 5px 5px rgba(50, 50, 50, 0.35);
        box-shadow:         0px 2px 5px 5px rgba(50, 50, 50, 0.35);
    }
}