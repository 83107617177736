.button {
    color: $content-button-text-color;
    background: $content-button-background-color;

    margin: .5em;
    padding: 0.75em 1em 0.75em 1em;

    text-transform: uppercase;

    &:not(.special):hover {
        color: $content-button-text-color-hover;
    }

    &.special {
        padding: 0!important;
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        position: relative;
        overflow: hidden;
        background: none;
        
        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            left: 0;
            right: 100%;
            top: 0;
            background: $content-button-special-effect-background-color;
            height: $content-button-special-effect-thickness;
            -webkit-transition-property: right;
            transition-property: right;
            -webkit-transition-duration: $content-button-special-animation-duration;
            -moz-transition-duration: $content-button-special-animation-duration;
            -ms-transition-duration: $content-button-special-animation-duration;
            -o-transition-duration: $content-button-special-animation-duration;
            transition-duration: $content-button-special-animation-duration;
            -webkit-transition-timing-function: $content-button-special-animation-curve;
            -moz-transition-timing-function: $content-button-special-animation-curve;
            -ms-transition-timing-function: $content-button-special-animation-curve;
            -o-transition-timing-function: $content-button-special-animation-curve;
            transition-timing-function: $content-button-special-animation-curve;
        }
        
        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            left: 100%;
            right: 0;
            bottom: 0;
            background: $content-button-special-effect-background-color;
            height: $content-button-special-effect-thickness;
            -webkit-transition-property: left;
            transition-property: left;
            -webkit-transition-duration: $content-button-special-animation-duration;
            -moz-transition-duration: $content-button-special-animation-duration;
            -ms-transition-duration: $content-button-special-animation-duration;
            -o-transition-duration: $content-button-special-animation-duration;
            transition-duration: $content-button-special-animation-duration;
            -webkit-transition-timing-function: $content-button-special-animation-curve;
            -moz-transition-timing-function: $content-button-special-animation-curve;
            -ms-transition-timing-function: $content-button-special-animation-curve;
            -o-transition-timing-function: $content-button-special-animation-curve;
            transition-timing-function: $content-button-special-animation-curve;
        }
        
        &:hover, &:focus, &:active {
            &:before {
                right: 0;
            }

            &:after {
                left: 0;
            }
        }

        & > span {
            padding: 0.75em 1em 0.75em 1em;

            display: inline-block;
            vertical-align: middle;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            overflow: hidden;
            position: initial;

            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                left: 0;
                top: 0;
                bottom: 100%;
                background: $content-button-special-effect-background-color;
                width: $content-button-special-effect-thickness;
                -webkit-transition-property: bottom;
                transition-property: bottom;
                -webkit-transition-duration: $content-button-special-animation-duration;
                -moz-transition-duration: $content-button-special-animation-duration;
                -ms-transition-duration: $content-button-special-animation-duration;
                -o-transition-duration: $content-button-special-animation-duration;
                transition-duration: $content-button-special-animation-duration;
                -webkit-transition-timing-function: $content-button-special-animation-curve;
                -moz-transition-timing-function: $content-button-special-animation-curve;
                -ms-transition-timing-function: $content-button-special-animation-curve;
                -o-transition-timing-function: $content-button-special-animation-curve;
                transition-timing-function: $content-button-special-animation-curve;
            }

            &:after {
                content: "";
                position: absolute;
                z-index: -1;
                right: 0;
                top: 100%;
                bottom: 0;
                background: $content-button-special-effect-background-color;
                width: $content-button-special-effect-thickness;
                -webkit-transition-property: top;
                transition-property: top;
                -webkit-transition-duration: $content-button-special-animation-duration;
                -moz-transition-duration: $content-button-special-animation-duration;
                -ms-transition-duration: $content-button-special-animation-duration;
                -o-transition-duration: $content-button-special-animation-duration;
                transition-duration: $content-button-special-animation-duration;
                -webkit-transition-timing-function: $content-button-special-animation-curve;
                -moz-transition-timing-function: $content-button-special-animation-curve;
                -ms-transition-timing-function: $content-button-special-animation-curve;
                -o-transition-timing-function: $content-button-special-animation-curve;
                transition-timing-function: $content-button-special-animation-curve;
            }

            &:hover, &:focus, &:active {
                color: $content-button-special-text-color-hover;
                background: $content-button-special-background-color-hover;
                transition: all $content-button-special-animation-duration ease-out;
                transition-delay: $content-button-special-animation-duration;

                &:before {
                    bottom: 0;
                }
    
                &:after {
                    top: 0;
                }
            }
        }
    }
}

.button-group {
    display: flex;
    width: 100%;
    margin-top: 25px;

    & > .button {
        width: 100%;
        height: auto;
        text-align: center;
    }
}