$default-font-size: 24px;

/*Z-Index*/
$menu-overlay-z-index: 1000;
$move-menu-z-index: 999;
$bottom-menu-z-index: 998;

$contact-form-form-fields-input: 902;
$contact-form-form-fields-label: 901;
$contact-form-z-index: 900;

$accent1Color: #9e0b63;
$accent2Color: #0892b2;
$textColor: #ffffff;

$p-color: #ef6c00;
$p-color-light: #ff9d3f;
$p-color-dark: #b53d00;
$s-color: #ff8f00;
$s-color-light: #ffc046;
$s-color-dark: #c56000;

$p-text-color: #ffffff;
$p-light-text-color: #ffffff;
$p-dark-text-color: #ffffff;
$s-text-color: #ffffff;
$s-light-text-color: #ffffff;
$s-dark-text-color: #ffffff;

$duration-animation-short: .2s;
$duration-animation-long: .4s;

$animation-duration: $duration-animation-long;

/*
Component > Menu
*/
$menuHeight: 60px;
$menuHamburgerColor: black;
$menuHamburgerColorHover: $accent2Color;
$menuHamburgerCheckedColorHover: $accent1Color;
$menuHamburgerCheckedColor: $textColor;

$menuBackground: linear-gradient(225deg, $accent1Color 0,$accent2Color 75%);
$menuItemColor: $textColor;

//component/main/background
$main-background-color: white;

//compoent/content/header
$content-header-color: $p-color;

//component/menu/menu
$menu-background: linear-gradient(225deg, $accent1Color 0,$accent2Color 75%);

$menu-item-text-color: $textColor;
$menu-item-text-color-hover: $menu-item-text-color;

$menu-hamburger-background-color: $accent1Color;
$menu-hamburger-background-color-hover: $p-color-light;
$menu-hamburger-icon-color: black;
$menu-hamburger-icon-color-hover: lighten(black, 50%);

//component/content/top-bar
$content-top_bar-height: 60px;
$content-top_bar-background-color: $p-color;

//component/content/button
$content-button-text-color: white;
$content-button-text-color-hover: darken(white, 15%);
$content-button-background-color: $p-color;

$content-button-special-animation-duration: .3s;
$content-button-special-animation-curve: ease-out;
$content-button-special-text-color-hover: $p-color;
$content-button-special-background-color-hover: white;
$content-button-special-effect-background-color: white;
$content-button-special-effect-thickness: 3px;

//component/content/contact-form
$content-contact_form-background-color: $s-color-dark;

$content-contact_form-form-field-height: 14px;

$content-contact_form-form-field-color: $p-color;
$content-contact_form-form-field-border-color: $p-color;
$content-contact_form-form-field-border-thickness: 1px;

$content-contact_form-form-field-effect-color: $p-color;
$content-contact_form-form-field-effect-thickness: 3px;